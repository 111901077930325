import { useMutation, useQuery } from '@tanstack/react-query';
import { ROOT_API } from 'constants/common';
import { request } from 'utils/request';

export const getDetailTips = (params) => request.get(ROOT_API + `/api/v1/match-review/get-detail-in-bo/${params}`);
export const getDetailPost = (params) => request.get(ROOT_API + `/api/v1/posts-match/${params}`);
export const submitResult = (data) => request.post(ROOT_API + '/api/v1/match-review/submit-result', data);
export const changeStatusPost = (id) => request.post(ROOT_API + `/api/v1/posts-match/change-show/${id}`);
export const deletePostMutation = (data) => request.post(ROOT_API + `/api/v1/posts-match/delete`, data);

export const useSubmitResultMutation = () => useMutation({ mutationFn: submitResult });
export const useChangeStatusPostMutation = () => useMutation({ mutationFn: changeStatusPost });
export const useDeletePostMutation = () => useMutation({ mutationFn: deletePostMutation });

export const useQueryGetDetailTips = (params = {}, options = {}) => useQuery({ queryKey: ['GET_DETAIL_TIPS', params], queryFn: () => getDetailTips(params), ...options });
export const useQueryGetDetailPost = (params = {}, options = {}) => useQuery({ queryKey: ['getDetailPost', params], queryFn: () => getDetailPost(params), ...options });