import { useMutation, useQuery } from '@tanstack/react-query';
import { ROOT_API } from 'constants/common';
import { request } from 'utils/request';

export const getListMember = (params) => request.get(ROOT_API + '/api/v1/member/members', { params });
export const getListCategories = (params) => request.get(ROOT_API + '/api/v1/categories', { params });
export const deleteCategory = (data) => request.post(ROOT_API + '/api/v1/categories/delete', data);
export const getListReviewer = (params) => request.get(ROOT_API + '/api/v1/reviewer/get-all', { params });
export const getListTip = (params) => request.get(ROOT_API + '/api/v1/match-review/get-all', { params });
export const getListOpinion = (params) => request.get(ROOT_API + '/api/v1/posts-match', { params });
export const getHistoryMatchReviewById = (reviewId, params) => request.get(ROOT_API + `/api/v1/review/history-match-review/${reviewId}`, { params });
export const getListLeagues = (params) => request.get(ROOT_API + '/api/v1/league/leagues-bo', { params });

export const createTopLeague = (data) => request.post(ROOT_API + '/api/v1/league/set-hot', data);

export const updateActiveLeague = (data) => request.post(ROOT_API + '/api/v1/league/update-status', data);

export const updateLeague = (data) => request.post(ROOT_API + '/api/v1/league/update', data);

export const deleteLeague = (data) => request.post(ROOT_API + '/api/v1/league/delete', data);
export const getListMatches = (params) => request.get(ROOT_API + '/api/v1/match/getListMatches', { params });

export const updateThumnail = async (data) => {
  return await request.post(ROOT_API + '/api/v1/common/upload-thumbnail', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const createCategory = data => request.post('/api/v1/categories/create', data);

export const updateCategory = data => request.post('/api/v1/categories/update', data);

// Mutation
export const useUpdateThumnailMutation = () => useMutation({ mutationFn: updateThumnail });

export const useDeleteCategoryMutation = () => useMutation({ mutationFn: deleteCategory });

export const useCreateTopLeagueMutation = () => useMutation({ mutationFn: createTopLeague });

export const useUpdateActiveLeagueMutation = () => useMutation({ mutationFn: updateActiveLeague });
export const useUpdateLeagueMutation = () => useMutation({ mutationFn: updateLeague });

export const useDeleteLeagueMutation = () => useMutation({ mutationFn: deleteLeague });

export const useCreateCategoryMutation = () => useMutation({ mutationFn: createCategory });
export const useUpdateCategoryMutation = () => useMutation({ mutationFn: updateCategory });
// Query
export const useQueryGetListMember = (params = {}, options = {}) => useQuery({ queryKey: ['getListMember', params], queryFn: () => getListMember(params), ...options });
export const useQueryGetListReviewer = (params = {}, options = {}) => useQuery({ queryKey: ['getListReviewer', params], queryFn: () => getListReviewer(params), ...options });
export const useQueryGetListTip = (params = {}, options = {}) => useQuery({ queryKey: ['getListTip', params], queryFn: () => getListTip(params), ...options });
export const useQueryGetListOpinion = (params = {}, options = {}) => useQuery({ queryKey: ['getListOpinion', params], queryFn: () => getListOpinion(params), ...options });
export const useQueryGetHistoryMatchReviewById = (reviewId, params = {}, options = {}) => useQuery({ queryKey: ['getHistoryMatchReviewById', params], queryFn: () => getHistoryMatchReviewById(reviewId, params), ...options });

export const useQueryGetListLeagues = (params = {}, options = {}) => useQuery({ queryKey: ['getListLeagues', params], queryFn: () => getListLeagues(params), ...options });

export const useQueryGetListMatches = (params = {}, options = {}) => useQuery({ queryKey: ['getListMatches', params], queryFn: () => getListMatches(params), ...options });


export const useQueryGetListCategory = (params = {}, options = {}) => useQuery({ queryKey: ['getListCategories', params], queryFn: () => getListCategories(params), ...options });
