import React from 'react';
import { Box, Text, Flex, Card, CardHeader, useColorModeValue, CardBody, Heading, useToast, Button } from '@chakra-ui/react';
import { useParams, useHistory } from 'react-router-dom';
import 'video-react/dist/video-react.css';
import { useQueryGetDetailPost } from 'services/tips';

const OpinionDetail = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const params = useParams();
  const { id } = params || {};
  const history = useHistory();
  const toast = useToast();
  const { data: detailTips } = useQueryGetDetailPost(id);
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card bg={'#fff'} overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px" pb={5}>
        <CardHeader>
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            {'Chi tiết nhận định'}
          </Text>
        </CardHeader>
        <CardBody pb={4}>
          <Card width={'60%'} mx={'auto'} mb={4} border={'1px'} borderColor={'#C0C0C0'}>
            <CardBody>
              <Flex direction="column" gap={'4px'} justifyContent="space-between" alignItems="center">
                <Heading size="md" mb={5}>
                  Nội dung nhận định cá nhân
                </Heading>
              </Flex>
              <Box>
                <div dangerouslySetInnerHTML={{ __html: detailTips?.data?.body }}></div>
              </Box>
              <Button w={'fit-content'} onClick={() => history?.goBack()} mt={5}>
                Quay lại
              </Button>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default OpinionDetail;
