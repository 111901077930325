import React from 'react';
import { BiFoodMenu } from "react-icons/bi";
import { MdOutlineChecklist, MdOutlinePostAdd, MdPreview } from "react-icons/md";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { HomeIcon, PersonIcon, StatsIcon } from 'components/Icons/Icons';
import { FaUser, FaImage } from "react-icons/fa";
import SignUp from 'views/Pages/SignUp.js';
import SignIn from 'views/Pages/SignIn';
import Profile from 'views/Dashboard/Profile';
import { Roles } from 'constants/common';
import Reviewers from 'views/Dashboard/Reviewers/Reviewers';
import HistoryMatchReview from 'views/Dashboard/HistoryMatchReview/index';
import Tips from 'views/Dashboard/Tips/Tips';
import League from 'views/Dashboard/Leagues/Leagues';
import Post from 'views/Dashboard/Post/Post';
import Matches from 'views/Dashboard/Matches/Matches';
import Category from 'views/Dashboard/Category/Category';
import Dashboard from 'views/Dashboard/Dashboard.js';
import CreatePostDialog from 'views/Dashboard/Post/components/CreatePost';
import TipDetail from 'views/Dashboard/Tips/components/TipDetail';
import BookMaket from 'views/Dashboard/BookMaket/BookMaket';
import { IoFootball } from 'react-icons/io5';
import BannerAds from 'views/Dashboard/BannerAds';
import Opinion from 'views/Dashboard/Opinion/Opinion';
import OpinionDetail from 'views/Dashboard/Opinion/components/OpinionDetail';
var dashRoutes = [
  {
    path: '/dashboard',
    name: 'Trang chủ',
    icon: <HomeIcon color="inherit" />,
    layout: '/admin',
    component: Dashboard,
    role: [Roles.ADMIN, Roles.SUPPORTER],
  },
  {
    path: '/reviewer/history-match-review/:id',
    redirect: true,
    name: 'Quản lý reviewer',
    icon: <BiFoodMenu size={20} />,
    layout: '/admin',
    component: HistoryMatchReview,
    role: [Roles.ADMIN,Roles.SUPPORTER],
  },
  {
    path: '/reviewers',
    name: 'Quản lý nhận định viên',
    icon: <FaUser />,
    layout: '/admin',
    component: Reviewers,
    role: [Roles.ADMIN,Roles.SUPPORTER],
  },
  {
    path: '/bet',
    name: 'Kèo',
    icon: <MdPreview size={20} />,
    layout: '/admin',
    component: Category,
    role: [Roles.ADMIN],
  },
  {
    path: '/bookmaket',
    name: 'Nhà cái',
    icon: <MdPreview size={20} />,
    layout: '/admin',
    component: BookMaket,
    role: [Roles.ADMIN],
  },

  {
    path: '/leagues',
    name: 'Giải đấu',
    icon: <IoFootball size={20} />,
    layout: '/admin',
    component: League,
    role: [Roles.ADMIN],
  },
  {
    path: '/matches',
    name: 'Trận đấu',
    icon: <IoFootball size={20} />,
    layout: '/admin',
    component: Matches,
    role: [Roles.ADMIN],
  },
  {
    path: '/posts/create',
    name: 'Tạo bài viết',
    icon: <StatsIcon color="inherit" size={20} />,
    redirect: true,
    component: CreatePostDialog,
    layout: '/admin',
    role: [Roles.ADMIN],
  },
  {
    path: "/posts/:id",
    name: "Chi tiết bài viết",
    icon: <StatsIcon color="inherit" size={20} />,
    redirect: true,
    component: CreatePostDialog,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: '/posts',
    name: 'Bài viết',
    icon: <StatsIcon color="inherit" size={20} />,
    layout: '/admin',
    component: Post,
    role: [Roles.ADMIN],
  },
  {
    path: '/banner',
    name: 'Banner',
    icon: <FaImage color="inherit" />,
    layout: '/admin',
    component: BannerAds,
    role: [Roles.ADMIN],
  },
  {
    path: '/tips/:id',
    name: 'Chi tiết nhận định',
    icon: <MdOutlineTipsAndUpdates size={20} />,
    redirect: true,
    component: TipDetail,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: '/tips',
    name: 'Nhận định',
    icon: <MdOutlineTipsAndUpdates size={20} />,
    layout: '/admin',
    component: Tips,
    role: [Roles.ADMIN],
  },
  {
    path: '/opinions',
    name: 'Nhận định cá nhân',
    icon: <MdOutlineChecklist size={20} />,
    layout: '/admin',
    component: Opinion,
    role: [Roles.ADMIN],
  },
  {
    path: '/opinion/:id',
    name: 'Chi tiết nhận định cá nhân',
    icon: <MdOutlineTipsAndUpdates size={20} />,
    redirect: true,
    component: OpinionDetail,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: '/sign-in',
    layout: '/auth',
    redirect: true,
    component: SignIn,
  },
  {
    path: '/sign-up',
    layout: '/auth',
    redirect: true,
    component: SignUp,
  },
  // {
  //   name: 'ACCOUNT PAGES',
  //   category: 'account',
  //   rtlName: 'صفحات',
  //   state: 'pageCollapse',
  //   views: [
  //     {
  //       path: '/profile',
  //       name: 'Profile',
  //       icon: <PersonIcon color="inherit" />,
  //       secondaryNavbar: true,
  //       layout: '/admin',
  //       component: Profile,
  //       role: [Roles.ADMIN, Roles.USER, Roles.GUEST],
  //     },
  //     {
  //       path: '/sign-in',
  //       layout: '/auth',
  //       redirect: true,
  //       component: SignIn,
  //     },
  //     {
  //       path: '/sign-up',
  //       layout: '/auth',
  //       redirect: true,
  //       component: SignUp,
  //     },
  //   ],
  // },
];

export default dashRoutes;